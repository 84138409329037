import {
  Wrapper,
  FooterTop,
  FooterGrid,
  PrimaryNav,
  FooterBottom,
  Copyright,
} from './Footer.style.js';
import { primaryNav, social, socialAllLink } from './FooterData';
import { GettyLogo } from '../../../../public/logos/GettyLogo';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Button } from '../../Common/Button/Button';
import { CookiesModal } from '../CookieBanner/CookiesModal';
import { useState } from 'react';
export const Footer = () => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const lang = router.locale;
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Wrapper>
      <CookiesModal open={open} onClose={onClose} footer />
      <FooterTop>
        {social.map((item) => {
          return (
            <a
              target="_blank"
              key={item.text}
              href={item.url}
              rel="noreferrer"
              aria-label={item.text}
            >
              {item.icon}
            </a>
          );
        })}
        <a
          target="_blank"
          href={socialAllLink}
          rel="noreferrer"
          aria-label={t('see all')}
        >
          {t('see all')}
        </a>
      </FooterTop>

      <FooterGrid>
        <GettyLogo alt />

        <PrimaryNav>
          {primaryNav.map((item) => {
            return (
              <a
                target="_blank"
                key={item.url}
                href={item.url}
                rel="noreferrer"
                aria-label={item.text[lang]}
              >
                {item.text[lang]}
              </a>
            );
          })}
        </PrimaryNav>
      </FooterGrid>
      <FooterBottom>
        <Copyright href="https://www.getty.edu/about/" target="_blank">
          Ⓒ J. Paul Getty Trust
        </Copyright>
      </FooterBottom>
      <Button
        type={'text'}
        onClick={() => {
          setOpen(true);
        }}
      >
        View cookies
      </Button>
    </Wrapper>
  );
};
