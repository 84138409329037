import styled, { createGlobalStyle } from 'styled-components';
import Head from 'next/head';
export const Theme = {
  color: {
    primary: '#047590',
    primaryLight: '#E0EFF1',
    primaryDark: '#01486E',
    secondary: '#F5CA84',
    darkWhite: '#F4F3F2', // search bar etc
    lightBeige: '#FFFAF2', //bg color
    darkBeige: '#F4F3ED', //table alt color
    black1: '#202020',
    black2: '#272727',
    grey: '#6F6F6F',
    grey1: '#524E40',
    lightgrey: '#F8F9FA',
    danger: '#DF542A',
    warning: '#D9B166',
    success: '#708C6D',
    info: '#0374B1',
    hrGrey: 'rgba(111, 111, 111, 0.22)',
    hrBlue: 'rgba(4, 128, 144, 0.14)',
  },
  shadow: '0 3px 7px rgba(0, 0, 0, 0.03)',
  shadow2: '0 6px 14px rgba(0, 0, 0, 0.06)',
};

export const GlobalStyle = createGlobalStyle`
  
  @font-face {
    font-family: 'Graphik';
    src: local('../assets/fonts/Graphik-Regular-Web.woff');
  }
  @font-face {
    font-family: 'Graphik';
    src: local('../assets/fonts/Graphik-Bold-Web.woff');
    font-weight: 600;
  }
  html {
    margin: 0;
    padding: 0;
    font-size: 14px;
    background-color: ${Theme.color.lightBeige};
    font-family: 'Open Sans', sans-serif;
  }

  body {
    overflow-x: hidden;
    position: relative;
    padding: 0;
    margin: 0;
  }

  svg {
    box-sizing: border-box;
    vertical-align: middle;
    font-size: 18px;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 25px;
  }
  h1 {
    font-family: 'Lora', serif;
    font-size: 36px;
    font-weight: normal;
  }
  h2 {
    font-family: 'Lora', serif;
    font-size: 24px;
    font-weight: normal;
  }
  h3 {
    font-family: 'Lora', serif;
    font-weight: normal;
    font-size: 18px;
  }
  h4 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 16px;
  }
  h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
  }
  h6 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 12px;
  }
  a {
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }
  * {
    box-sizing: border-box;
  }`;
const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BasicLayout = ({ children }) => {
  return (
    <>
      <Head>
        <title>Digital Florentine Codex</title>
        <meta
          property="og:title"
          content="Digital Florentine Codex"
          key="title"
        />
        <meta
          name="viewport"
          lang="en"
          content="initial-scale=1.0, width=device-width"
        />
      </Head>
      <GlobalStyle />
      <Flex>{children}</Flex>
    </>
  );
};

export default BasicLayout;
