import { Container, Wrapper, SearchIcon } from './SearchBar.style.js';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { AutocompleteReq } from '../../api/AutocompleteReq';
import { SearchAutoComplete } from '../SearchAutoComplete/SearchAutoComplete';

export const SearchBar = ({ closeSearch, mobileshow, mobilehide, width }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);

  const StartAutoComplete = async (val) => {
    setLoading(true);
    try {
      const res = await fetch(AutocompleteReq('search', val, '', router.query));
      const data = await res.json();
      setOptions(data);
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
    }
  };
  const handleSearch = (type, val) => {
    closeSearch ? closeSearch() : '';
    type === 'image'
      ? router.push({
          pathname: '/search',
          query: {
            tags: `${val.value}|${val.label}|${val.lang}`,
            view: 'image',
          },
        })
      : router.push({
          pathname: '/search',
          query: { term: val, view: 'text', filters: '' },
        });
  };

  return (
    <Container mobileshow={mobileshow} mobilehide={mobilehide}>
      <Wrapper width={width}>
        <SearchIcon mobileshow={mobileshow} />
        <SearchAutoComplete
          onSelect={(type, val) => {
            handleSearch(type, val);
          }}
          options={options}
          label={t('codex')}
          onChange={(e) => {
            StartAutoComplete(e);
          }}
          loading={loading}
        ></SearchAutoComplete>
      </Wrapper>
    </Container>
  );
};
