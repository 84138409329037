import styled from 'styled-components';
import { Theme } from '../../../layout/Basic';
import SearchBarIcon from '../../../public/icons/SearchBarIcon';

export const Container = styled.div`
  justify-content: center;
  width: 100%;

  display: ${(props) => (props.mobileshow ? 'none' : 'flex')};
  @media (max-width: 995px) {
    display: ${(props) => (props.mobilehide ? 'none' : '')};
    display: ${(props) => (props.mobileshow ? 'flex' : '')};
    padding: 0 10px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  background-color: ${Theme.color.darkWhite};
  border: 1px solid #c7c4c4;
  height: 45px;
  border-radius: 15px;
  width: 100%;
  max-width: ${(props) => (props.width ? props.width : '436px')};
`;
export const SearchIcon = styled(SearchBarIcon)`
  font-size: 18px;
  fill: black;
  margin: 14px;
`;
