import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from '../next-i18next.config.js';
import NextProgress from 'next-progress';
import { Footer } from '../src/components/Globals/Footer/Footer';
import { NavBar } from '../src/components/Globals/NavBar/NavBar';
import { CookieBanner } from '../src/components/Globals/CookieBanner/CookieBanner';
import * as fbq from '../fpixel';
import * as React from 'react';
import Script from 'next/script';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { getCookie, getCookies } from 'cookies-next';

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    if (getCookie('fb_pixel')) {
      fbq.consent();
      fbq.pageview();

      const handleRouteChange = () => {
        fbq.pageview();
      };

      router.events.on('routeChangeComplete', handleRouteChange);
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    } else {
      fbq.revoke();
    }
  }, [router.events]);
  return (
    <>
      <div className="container">
        {/*Google Tag Manager*/}
        {getCookie('google_analytics') && (
          <Script strategy="lazyOnload" id="google-analytics">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NRDC63K');`}
          </Script>
        )}
        {/*End Google Tag Manager*/}
      </div>
      {/* Global Site Code Pixel - Facebook Pixel */}
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
        }}
      />
      <NavBar />
      <NextProgress options={{ showSpinner: false }} />
      <Component {...pageProps} />
      <CookieBanner />
      <Footer />
    </>
  );
}

export default appWithTranslation(MyApp, nextI18NextConfig);
