import {
  Container,
  ModalContainer,
  ModalContent,
  ModalTop,
} from '../../Common/Modal/Modal.style';
import { Button } from '../../Common/Button/Button';
import CloseIcon from '../../../../public/icons/CloseIcon';
import { CookiesBottom, CookiesTable, CookiesTop } from './CookieBanner.style';
import { setCookie } from 'cookies-next';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
export const CookiesModal = ({ open, onClose, footer }) => {
  const { t } = useTranslation('common');

  const acceptAllCookie = () => {
    setCookie('cookie_policy', 'true', {});
    setCookie('google_analytics', 'true', {});
    setCookie('fb_pixel', 'true', {});
    onClose();
  };

  const acceptEssentialCookie = () => {
    setCookie('cookie_policy', 'true', {});
    setCookie('google_analytics', 'false', {});
    setCookie('fb_pixel', 'false', {});
    onClose();
  };

  return (
    <Container open={open}>
      <ModalContainer open={open} data-footer={footer}>
        <ModalTop>
          <h2> Cookies Notice </h2>

          <Button
            ariaLabel={t('close modal')}
            type="icon"
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon />
          </Button>
        </ModalTop>

        <ModalContent>
          <hr />
          <CookiesTop>
            <b>Essential cookies </b> <small>(Cannot be rejected)</small>
            <CookiesTable>
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Purpose</th>
                  <th>Expires</th>
                </tr>
                <tr>
                  <td>cookie_policy</td>
                  <td>Saves your cookie consent settings</td>
                  <td>session</td>
                </tr>
              </tbody>
            </CookiesTable>
            <b>Non essential cookies </b>
            <CookiesTable>
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Purpose</th>
                  <th>Expires</th>
                </tr>
                <tr>
                  <td>Google Analytics</td>
                  <td>
                    <p>
                      {' '}
                      To gather information on site visitors and their actions,
                      this is then used to used to improve user experience{' '}
                    </p>
                    <small>More info below</small>
                  </td>
                  <td>up to 1 year</td>
                </tr>
                <tr>
                  <td>Meta Pixel</td>
                  <td>
                    <p>
                      To gather information on site visitors and their actions,
                      this is then used to improve user experience and monitor
                      how effectiveness of our advertisements
                    </p>
                    <small>More info below</small>
                  </td>
                  <td>up to 3 months</td>
                </tr>
              </tbody>
            </CookiesTable>
            <small>Last Updated: December 5, 2022</small>
            <p>
              This Cookies Notice (the <b>“Notice”</b>) explains how the J. Paul
              Getty Trust (the <b>“Getty”</b>) uses cookies, pixels, and other
              similar technologies to better understand how you use and interact
              with websites that link to this Notice (the <b>“Sites”</b>). It
              explains what these technologies are and why we use them, as well
              as your rights to control our use of them.
            </p>
            <h3>1. What Are Cookies?</h3>
            <p>
              Cookies are text files that are downloaded to your computer or
              mobile device when you visit a website and store certain
              information about website usage. They are useful because they
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              allow websites to recognize a user's device. The term “cookie” is
              used in this Notice in the broad sense to include all similar
              techniques and technology, including web beacons, pixels, and log
              files. For more information on how cookies work, go to All About
              Cookies.org.
            </p>
            <h3>
              2. Types of Information Collected and Reasons for Collection
            </h3>
            <p>
              Cookies help us to better understand your use of our Sites and, as
              a result, allow us, among other things, to offer our web visitors
              a more personalized and consistent experience. Here are examples
              of the types of information that we collect through cookies:
            </p>
            <ul>
              <li>
                The number of users visiting the Sites, the frequency with which
                the Sites are viewed, the pages viewed, the clicks made on the
                Sites and the total duration of navigation;
              </li>
              <li>The name of the website visited prior to the Sites;</li>
              <li>
                Whether you are visiting our Sites for the first time or not;
              </li>
              <li>
                Your viewing preferences (e.g. layout, sizing preferences,
                language, etc.);
              </li>
              <li>
                Your type of browser used and your IP address, so that we can
                identify you during your next visits.
              </li>
            </ul>
            <small>
              Note that we do not collect your name or email address through
              cookies.
            </small>
            <p>In addition, cookies allow us to do the following:</p>
            <ul>
              <li>
                Customize your visit: We may use information collected during
                your visit to facilitate your next visit to our Sites.
              </li>
              <li>
                Customize the content and messages that appear on the Sites,
                whether ours or those of third parties: This involves, for
                example, identifying the features of our Sites that interest
                you.
              </li>
              <li>
                Analyze customer trends: We compile information on our Site
                visits and analyze them as a whole in aggregated form. This type
                of study aims to identify trends among many visitors to our
                Sites, rather than analyze the information of a particular
                visitor.
              </li>
              <li>
                Engage in online advertising: We can use information about
                visitors to our Sites to better target advertising for the Getty
                on other sites.
              </li>
            </ul>
            <p>
              To refuse these various types of cookies, please follow the
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              instructions below under the section “Cookie management."
            </p>
            <h3>3. Types of Cookies We Use</h3>
            <p>
              We use first-party and third-party cookies for several reasons:
            </p>
            <ul>
              <li>
                First-party cookies: These cookies are created by us. They are,
                for the most part, used to allow you to browse our Sites and use
                its features. They may also assist in analytics activities.
              </li>
              <li>
                Third-party cookies: In addition to our proprietary cookies,
                some third-party companies may place cookies on your browsers,
                access them, and associate web beacons with them. These cookies
                enable third-party features or functionalities to be provided on
                or through the Sites. The parties that set these third-party
                cookies can recognize your device both when it visits our Sites
                and also when it visits certain other websites.
              </li>
            </ul>
            <p>
              The cookies we use can either be temporary or permanent. Some
              cookies are necessary for technical reasons for our Sites to
              operate and/or provide basic functionality to you, and we refer to
              these as “strictly necessary” cookies. Other cookies enable us to
              track and target the interests of our users and to enhance the
              experience on our Sites. The specific types of first- and
              third-party cookies served through our Sites and the purposes they
              perform are described in more detail as follows:
            </p>
            <ul>
              <li>
                Strictly necessary cookies: These cookies are strictly necessary
                to provide you with services available through our Sites and
                cannot be turned off. These cookies allow you to use some of the
                Site’s features, such as access to secure areas where payments
                can be made
              </li>
              <li>
                Functional cookies: These cookies allow you to browse our Sites
                or benefit from some enhanced features, such as memorizing
                language preferences. Similar to strictly necessary cookies, if
                these cookies are disabled, it could impact your experience to
                use some Site functionality, such as your set preferences.
              </li>
              <li>
                Analytics cookies: These cookies allow us or our third-party
                analytics providers to collect information and statistics on the
                use of the Sites by you and other visitors. Thanks to this
                information, we are able to improve the features offered on our
                Sites.
              </li>
              <li>
                Targeting cookies: These cookies, provided by our third-party
                advertising partners, collect information about your browsing
                habits, and your usage of our Sites. This information allows us
                and our third-party advertising providers to display relevant
                content and advertisements on our Sites and other sites you
                visit.
              </li>
            </ul>
            <h3>4. Cookie Management</h3>
            <p>
              You can exercise your preference to receive all types of cookies
              by managing cookies from your web browser. As the means by which
              to activate or deactivate cookies varies from one web browser to
              another, you should visit your web browser’s help menu for more
              information on cookie preferences. You can also configure your
              browser at any time to be notified of the receipt of a cookie, so
              that you can decide whether you want to accept it or not. Cookies
              do not under any circumstances read the data from your hard drive
              and you can delete them from your computer at any time. At the
              moment, it is technically impossible for you to synchronize your
              settings between your browsers and your devices (your computer or
              your smartphone). You must set them on each browser/device that
              you currently use and each time you use a new browser or device.
              Please note that if you choose to disable or deactivate cookies,
              this will apply to all cookies and have the consequences on Site
              functionality discussed in the section “Consequences of
              deactivation of all cookies” below.
            </p>
            <p>
              Targeting cookies: If you are interested in more information about
              tailored online advertising and how you can generally control
              cookies from being put on your computer to deliver tailored
              advertising, you may visit
              <Link
                target="_blank"
                href={'https://optout.networkadvertising.org/?c=1#!'}
              >
                the Network Advertising Initiative’s Consumer Opt-Out link
              </Link>
              , the Digital Advertising{' '}
              <Link
                target="_blank"
                href={'http://optout.aboutads.info/?c=2#!/'}
              >
                Alliance’s Consumer Opt-Out link
              </Link>
              , or Your Online Choices to opt-out of receiving tailored tailored
              advertising from companies that participate in those programs.
            </p>
            <h3>5. Consequences of Deactivation of All Cookies</h3>
            <p>
              f you choose to deactivate cookies, you may continue to use
              certain parts of our Sites. However, some useful features may not
              work anymore, depending on which cookies you deactivate. For
              example, the Sites and their content may not be as relevant to
              your preferences. Please note that if you have disabled one or
              more cookies, we may continue to use the information that was
              collected by such cookies before they were deactivated. However,
              we will cease to collect any information via the opted-out cookie
              once a cookie has been deactivated.
            </p>
            <h3>6. Changes to the Cookies Notice</h3>
            <p>
              This Notice may be revised occasionally. Any changes made will be
              reflected on this Site. Please revisit this Notice regularly to
              stay informed about our use of cookies. If you have any questions,
              please contact us as explained below.
            </p>
            <h3>7. Contact us</h3>
            <p>
              If you have questions about the use of cookies on our Sites, do
              not hesitate to contact us by email at web@getty.edu. You can also
              find more general information about the types of information we
              collect about you and how you may exercise your data privacy
              rights in our Privacy Policy.
            </p>
          </CookiesTop>
        </ModalContent>

        <hr />
        <CookiesBottom>
          <Button
            square
            onClick={() => {
              acceptAllCookie();
            }}
          >
            Accept analytics cookies
          </Button>
          <Button
            square
            secondary
            onClick={() => {
              acceptEssentialCookie();
            }}
          >
            Reject analytics cookies
          </Button>
        </CookiesBottom>
      </ModalContainer>
    </Container>
  );
};
