import {
  Arrowdown,
  Dropdown,
  MobileLanguage,
  LangOption,
  Radio,
} from './LanguagePicker.style.js';
import { Select } from '../Common/Select/Select';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export const LanguagePicker = () => {
  const router = useRouter();
  const lang = router.locale.toUpperCase();
  const { t } = useTranslation('common');

  const HandleLanguageSwitch = (val) => {
    const { pathname, asPath, query } = router;
    router.push({ pathname, query }, asPath, { locale: val.toLowerCase() });
  };
  return (
    <>
      <MobileLanguage>
        <p>{t('Language')}</p>
        <hr />
        <LangOption>
          <Radio
            aria-label={t('Language EN')}
            lang={lang}
            id="EN"
            onClick={() => {
              router.push(pathname, query, { locale: 'en' });
            }}
          />
          <p>{t('english')}</p>
        </LangOption>
        <LangOption>
          <Radio
            aria-label={t('Language ES')}
            lang={lang}
            id="ES"
            onClick={() => {
              HandleLanguageSwitch('es');
            }}
          />
          <p>{t('spanish')}</p>
        </LangOption>
      </MobileLanguage>

      <Dropdown>
        <Select
          aria={t('language picker')}
          placeholder=""
          options={['EN', 'ES']}
          initOption={lang}
          onSelect={(val) => {
            HandleLanguageSwitch(val);
          }}
        >
          <Arrowdown />
        </Select>
      </Dropdown>
    </>
  );
};
