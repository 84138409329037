import FaceBookIcon from '../../../../public/icons/FacebookIcon';
import TwitterXIcon from '../../../../public/icons/TwitterXIcon';
import InstaIcon from '../../../../public/icons/InstaIcon';
import YoutubeIcon from '../../../../public/icons/YoutubeIcon';
export const primaryNav = [
  {
    text: {
      en: 'Privacy Policy',
      es: 'Política de privacidad',
    },
    url: 'https://www.getty.edu/legal/privacy.html',
  },
  {
    text: {
      en: 'Terms of use',
      es: 'Términos de Uso',
    },
    url: 'https://www.getty.edu/legal/copyright.html',
  },
  {
    text: {
      en: 'Trademarks',
      es: 'Marcas registradas',
    },
    url: 'https://www.getty.edu/legal/trademarks/',
  },
];

export const social = [
  {
    icon: <FaceBookIcon />,
    text: 'Facebook',
    url: 'https://www.facebook.com/gettymuseum',
  },
  {
    icon: <TwitterXIcon />,
    text: 'X',
    url: 'https://twitter.com/gettymuseum',
  },
  {
    icon: <InstaIcon />,
    text: 'Instagram',
    url: 'https://www.instagram.com/gettymuseum/',
  },
  {
    icon: <YoutubeIcon />,
    text: 'YouTube',
    url: 'https://www.youtube.com/user/gettymuseum',
  },
];

export const socialAllLink = 'https://www.getty.edu/connect/';
