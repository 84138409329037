import styled from 'styled-components';
import { Theme } from '../../../layout/Basic';
import { BtnText } from '../Common/Button/Button.style';

export const ACWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const DropdownInput = styled.input`
  border: none;
  background-color: ${Theme.color.darkWhite};
  border-radius: 15px;
  width: 100%;
  padding: 0 20px;
  margin-bottom: ${(props) => (props.open ? '-5px' : '10px')};
  position: relative;
  display: flex;
  height: 100%;

  :hover {
    cursor: pointer;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.03);
  }
`;

export const Options = styled.div`
  opacity: ${(props) => (props.open ? '1' : '0')};
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  z-index: 12;
  position: relative;
  transition: all 0.3s linear;

  border: solid rgba(32, 32, 32, 0.35);
  border-width: 0 1px 1px 1px;
  box-shadow: ${Theme.shadow};
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: start;

  max-height: ${(props) => (props.open ? '600px' : '0')};
  overflow: auto;
  background-color: ${Theme.color.darkWhite};
  color: black;
  width: auto;
  margin-left: -10%;

  margin-top: 10px;
  padding: 5px 20px;

  ${BtnText} {
    text-align: start;
    padding: 5px;
    font-size: 14px;
  }
`;

export const NoOption = styled.p`
  padding: 0 5px;
`;

export const ImgSearchOptions = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  padding-top: 15px;
  text-align: left;

  svg {
    font-size: 25px;
    fill: ${Theme.color.primary};
    margin: 5px;
  }
  button {
    display: flex;
    width: 95%;
    margin: auto;
    justify-content: space-between;
    height: auto;
    text-transform: none;
  }
  b {
    max-width: 50%;
  }
  span {
    max-width: 100%;
    font-weight: 500;
    font-style: italic;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
  }
`;
export const TxtSearchOptions = styled.div`
  border-bottom: 1px solid ${Theme.color.hrGrey};
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  margin-top: 5px;

  button {
    display: flex;
    width: 95%;
    margin: auto;
    height: auto;
    text-transform: none;
  }
  svg {
    fill: ${Theme.color.primary};
    font-size: 24px;
    border: 2px solid ${Theme.color.primary};
    border-radius: 5px;
    margin: 5px;
  }
`;
