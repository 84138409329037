import styled from 'styled-components';
import { Theme } from '../../../../layout/Basic';

export const Wrapper = styled.footer`
  background-color: ${Theme.color.darkWhite};
  padding: 40px;
  display: flex;
  flex-direction: column;

  @media (max-width: 850px) {
    padding: 16px 16px 60px 16px;
  }
  @media print {
    display: none;
  }
`;
export const FooterTop = styled.div`
  border-bottom: 1px solid #000000;
  display: flex;
  justify-content: end;
  align-items: center;

  @media (max-width: 600px) {
    justify-content: start;
  }

  & svg {
    font-size: 24px;
    fill: #1a1a1a;
  }

  & a {
    font-size: 15px;
    margin: 0 0 14px 20px;
    font-family: Graphik, sans-serif;
    color: #1a1a1a;
    font-weight: 500;

    @media (max-width: 850px) {
      font-size: 13px;
      line-height: 13px;
      margin: 0 36px 14px 0;
    }
  }
`;
export const FooterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-top: 12px;

  @media (max-width: 850px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    min-height: 170px;
  }
  //Getty logo
  svg {
    width: 212px;
    margin-right: 50px;
    @media (max-width: 850px) {
      width: 160px;
      margin-top: -10px;
    }
    @media (max-width: 600px) {
      width: 140px;
      padding-top: -20px;
    }
  }
`;

export const PrimaryNav = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 5px;
  a {
    font-family: Graphik, sans-serif;
    font-weight: 600;
    line-height: 24.15px;
    font-size: 21px;
    color: #1a1a1a;

    @media (max-width: 850px) {
      line-height: 19px;
      font-size: 16px;
    }

    @media (max-width: 600px) {
      padding-bottom: 8px;
    }
  }

  //left line
  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -24px;
    height: calc(100% + 20px);
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    pointer-events: none;

    @media (max-width: 850px) {
      left: -20px;
      height: calc(100% + 30px);
    }
  }
`;

export const FooterBottom = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  justify-content: end;
  display: flex;
`;

export const Copyright = styled.a`
  font-weight: 400;
  font-family: Graphik, sans-serif;
  font-size: 18px;
  padding-bottom: 12px;

  @media (max-width: 850px) {
    font-size: 16px;
  }
`;
