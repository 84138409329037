import styled from 'styled-components';
import { Theme } from '../../../layout/Basic';
import { LinkItem } from '../Globals/NavBar/NavBar.style';
export const Wrapper = styled.div`
  padding: 0 20px;

  @media (max-width: 995px) {
    justify-self: center;
    width: 100px;
    padding: 0;
  }
`;

export const DropdownHeader = styled(LinkItem)`
  padding: 10px;
`;

export const DropdownItem = styled.div`
  display: flex;
  justify-content: space-around;

  @media (max-width: 995px) {
    border-bottom: ${(props) => (props.open ? '1px solid white' : 'none')};
  }
`;
export const DropdownButton = styled.button`
  background-color: transparent;
  border: none;
  position: relative;

  :hover {
    text-decoration: underline;
    background-color: transparent;
    svg {
      fill: ${Theme.color.primaryDark};
      border-bottom: 1px solid ${Theme.color.primaryDark};
    }
  }
  svg {
    fill: white;
    font-size: 12px;
  }
  @media (max-width: 995px) {
    svg {
      fill: white;
      font-size: 14px;
    }
  }
`;

export const DropdownLink = styled.button`
  background-color: transparent;
  border: none;
  font-size: 13px;
  font-weight: 600;
  color: ${Theme.color.primary};
  text-decoration: none;
  transition: all 0.3s;
  padding: 10px 16px;
  white-space: nowrap;

  @media (max-width: 995px) {
    color: white;
    align-self: center;
  }

  :hover {
    text-decoration: underline;
    cursor: default;
  }
`;

export const Label = styled.div`
  font-size: 13px;
  font-weight: 600;
  text-decoration: ${(props) => (props.open ? 'underline' : 'none')};
  color: white;

  :hover {
    text-decoration: underline;
    cursor: ${(props) => (props.open ? 'pointer' : 'default')};
    color: ${(props) => (props.open ? '#e0eff1' : 'white')};
  }
`;
export const Menu = styled.div`
  display: flex;
  align-items: start;
  opacity: ${(props) => (props.open ? '1' : '0')};
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  z-index: 11;
  transition: all 0.3s linear;
  flex-direction: column;
  position: absolute;
  margin-top: 30px;
  background-color: #e0eff1;
  top: 50px;
  border-radius: 0;
  width: auto;
  padding: 5px 0;

  hr {
    width: 95%;
    border: 0.5px solid ${Theme.color.hrBlue};
    margin: 0 auto;
  }

  @media (max-width: 995px) {
    margin-top: 0;
    top: 0;
    position: relative;
    height: ${(props) => (props.open ? '100%' : '0')};
    background-color: transparent;
    hr {
      border-color: rgba(224, 239, 241, 0.15);
      width: 100%;
    }
  }
`;
