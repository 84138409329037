export const GettyLogo = () => (
  <svg
    width="238"
    height="96"
    viewBox="0 0 238 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M59 44H69V70C69 70 58.2728 76.1719 41.2661 76.2223C13.6062 76.3041 0.343201 60.588 0.400207 38.2064C0.45343 17.3019 15.8732 0.335026 41.4591 0.40015C52.8947 0.429264 62.4351 4.66101 66.733 7.34742L64.5308 10.8956C60.5904 8.3263 52.6368 4.50306 41.4487 4.47455C21.6582 4.42414 11.0604 19.1203 11.0117 38.2335C10.9615 57.931 22.5143 72.1034 41.277 72.1512C47.5081 72.1671 54.3895 70.1375 59 67V44ZM117.453 29.9701C121.189 34.0397 123 40 123 47V50L86.116 49.8333C86.0975 57.0819 87.6165 62.61 90.674 66.4158C92.397 68.5607 94.6799 70.1644 97.2729 71.0798C100.187 72.1089 103.417 72.3529 106.478 72.0312C109.79 71.6832 113.026 70.6132 115.886 68.9059C116.772 68.3766 117.64 67.8071 118.446 67.1611L120.493 70.2751C117.7 72.2896 114.605 73.8824 111.588 74.8851C108.571 75.8882 105.407 76.3856 102.097 76.3771C94.6038 76.3581 88.5287 73.9298 83.8714 69.0908C79.2132 64.2523 76.8949 57.9647 76.9146 50.2281C76.9349 42.2477 79.1901 35.8499 83.6807 31.0342C88.1707 26.2189 94.1453 23.8204 101.603 23.8394C108.433 23.8568 113.717 25.9009 117.453 29.9701ZM114 46C114.019 38.5919 113.215 34.6116 110.853 31.6261C108.49 28.6402 105.358 27.1425 101.176 27.1318C96.9944 27.1212 93.5313 28.8295 90.7869 32.2543C88.0421 35.6806 86.2233 40.4252 86 46H114ZM140 63V29H154V25H140V11L132 13V25H126V29H132C132 29 132.023 62.3574 132.023 62.2482C132.003 69.8475 136.614 76.451 145.126 76.4867C148.227 76.4997 151.846 75.6412 155.946 73.6419L155.081 71.4432C155.081 71.4432 139.963 77.6273 140 63ZM173 63V29H187V25H173V11L165 13V25H158V29H165C165 29 165 62.1092 165 62C164.981 69.5994 169.215 76.5341 177.727 76.5697C180.828 76.5826 184.446 75.7242 188.547 73.7249L187.681 71.5261C187.681 71.5261 172.963 77.6273 173 63ZM201.429 96H207.431L238 25H232L215.506 63.2942L199.423 25H190L210.736 74.286L201.429 96Z"
      fill="currentColor"
    />
  </svg>
);
