export const AutocompleteReq = (type, val, tagGroup, router) => {
  const tag_g = tagGroup ? `tag_group=${tagGroup}` : '';
  const val_q =
    type === 'folio'
      ? val
        ? `book=${router.book}&folio_q=${val}`
        : `book=${router.book}&folio=${router.folio}`
      : val
      ? `&tag_q=${val}`
      : '';

  const queryTag =
    router.tags && router.tags.length > 0 ? `&facet=${router.tags}` : '';

  const queryFilters =
    router.filters && Array.isArray(router.filters)
      ? '&facet=' + router.filters.toString().replace(/,/g, '&facet=')
      : router.filters
      ? `&facet=${router.filters}`
      : '';

  if (type === 'search') {
    return encodeURI(
      `${process.env.NEXT_PUBLIC_DB_HOST}/codex/tag_autocomplete/?${val_q}`,
    );
  } else if (type === 'folio') {
    return encodeURI(
      `${process.env.NEXT_PUBLIC_DB_HOST}/codex/folio_autocomplete/?${val_q}`,
    );
  } else {
    return encodeURI(
      `${process.env.NEXT_PUBLIC_DB_HOST}/codex/tag_autocomplete/?${tag_g}${val_q}${queryTag}${queryFilters}`,
    );
  }
};
