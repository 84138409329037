import { BtnSolid, BtnText, BtnIcon } from './Button.style';

export const Button = ({
  type,
  onClick,
  ariaLabel,
  uppercase,
  disabled,
  children,
  primary,
  secondary,
  square,
  tabIndex,
  outlined,
}) => {
  let button;

  if (type === 'text') {
    button = (
      <BtnText
        outlined={outlined}
        tabIndex={tabIndex}
        disabled={disabled}
        uppercase={uppercase}
        onClick={onClick}
        aria-label={ariaLabel}
        primary={primary}
      >
        {children}
      </BtnText>
    );
  } else if (type === 'icon') {
    button = (
      <BtnIcon
        disabled={disabled}
        onClick={onClick}
        aria-label={ariaLabel}
        secondary={secondary}
        primary={primary}
      >
        {children}
      </BtnIcon>
    );
  } else {
    button = (
      <BtnSolid
        disabled={disabled}
        onClick={onClick}
        aria-label={ariaLabel}
        secondary={secondary}
        square={square}
        uppercase={uppercase}
      >
        {children}
      </BtnSolid>
    );
  }

  return button;
};
