import styled from 'styled-components';
import MenuIcon from '../../../../public/icons/MenuIcon';
import { Theme } from '../../../../layout/Basic';
import { Container } from '../../SearchBar/SearchBar.style';

export const Nav = styled.nav`
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  max-height: 80px;
  top: 0;
  left: 0;
  background-color: ${Theme.color.primary};
  border-bottom: 1px solid white;
  z-index: 111;
  //text-transform: capitalize;


  @media print {
    display: none;
  }

  @media (max-width: 995px) {
    justify-content: space-between;
`;
export const Shadow = styled.div`
  position: absolute;
  top: 80px;
  height: 100vh;
  transition: opacity ease 0.3s;
  background-color: rgba(32, 32, 32, 0.7);
  opacity: ${({ openLeft, openRight }) => (openRight || openLeft ? '1' : '0')};
  z-index: ${({ openLeft, openRight }) => (openRight || openLeft ? '12' : '0')};
  width: ${({ openLeft, openRight }) =>
    openRight || openLeft ? '100vw' : '0'};
  right: 0;
  cursor: pointer;
`;

export const NavMenu = styled.div`
  display: flex;
  height: 80px;
  align-items: center;
  padding: 10px;
`;

export const Brand = styled.div`
  font-size: 28px;
  padding: 15px;
  font-family: 'Lora', serif;
  color: #ffffff;
  font-weight: normal;
  text-decoration: none;
  white-space: nowrap;

  :hover {
    text-decoration: underline;
    cursor: default;
  }

  @media (max-width: 800px) {
    font-size: 3vw;
    align-self: center;
    white-space: normal;
  }
  @media (max-width: 700px) {
    font-size: 23px;
  }
`;

export const Links = styled.div`
  display: ${({ direction }) => (direction === 'right' ? 'none' : 'flex')};
  align-items: center;
  @media (max-width: 995px) {
    top: 80px;
    right: ${({ direction }) => (direction === 'right' ? '0' : 'auto')};
    background-color: ${Theme.color.primary};
    overflow: hidden;
    width: ${({ direction, isOpen }) =>
      direction === 'left' ? (isOpen ? '90%' : '0') : isOpen ? '100%' : '0'};
    transition: width ease-in 0.3s;
    z-index: 13;
    position: absolute;
    height: 100vh;
    overflow-y: scroll;
    padding-bottom: 80px;
    display: grid;
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(auto, 10px);
    align-content: ${({ direction }) =>
      direction === 'right' ? 'start' : 'auto'};
    justify-content: right;

    grid-row-gap: 0;
    //searchbar
    ${Container} {
      padding-top: 50px;
    }
  }
`;

export const LinkItem = styled.a`
  font-size: 13px;
  font-weight: 600;
  color: white;
  text-decoration: none;
  transition: all 0.3s;
  padding: 20px;
  white-space: nowrap;

  :hover {
    text-decoration: underline;
    cursor: default;
  }

  @media (max-width: 1120px) {
    padding: 20px 10px;
  }
  @media (max-width: 995px) {
    align-self: center;
    justify-self: center;
    text-align: left;
    width: 100px;

    :hover {
      color: ${Theme.color.primaryLight};
    }
  }
`;

export const SearchHistory = styled.div`
  text-transform: uppercase;
  padding: 20px;
  font-family: 'Open Sans', sans-serif;
`;
export const MobileIcons = styled.div`
  display: none;
  color: #ffffff;
  flex-direction: column;
  cursor: pointer;
  font-size: 24px;
  svg {
    font-size: 20px;
  }
  @media (max-width: 995px) {
    display: flex;
  }
`;

export const HamburgerIcon = styled(MenuIcon)``;
