import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useOuterClick } from '../../hooks/useOuterClick';
import {
  Wrapper,
  Menu,
  DropdownLink,
  DropdownButton,
  DropdownItem,
  DropdownHeader,
} from './NavDropdown.style';
import Link from 'next/link';
import { useRouter } from 'next/router';
import ChevronDownIcon from '../../../public/icons/ChevronDownIcon';

export const NavDropdown = ({ options, title }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('common');
  const router = useRouter();
  const lang = router.locale;

  const innerRef = useOuterClick((ev) => {
    setOpen(false);
  });

  return (
    <Wrapper ref={innerRef}>
      <DropdownItem open={open}>
        <Link passHref href={`/${title}`}>
          <DropdownHeader>{t(title)}</DropdownHeader>
        </Link>
        <DropdownButton
          id={`${title}-menu-button`}
          aria-haspopup="true"
          aria-controls={`${title}-menu`}
          open={open}
          onClick={() => setOpen(!open)}
          onFocus={() => setOpen(true)}
        >
          <ChevronDownIcon />
        </DropdownButton>
      </DropdownItem>

      <Menu
        aria-labelledby={`${title}-menu-button`}
        open={open}
        role={'menu'}
        onMouseLeave={() => setOpen(false)}
        ref={innerRef}
      >
        {options.map((option, i) => {
          return (
            <Link
              href={option.slug}
              key={i}
              role={'menuitem'}
              tabIndex={open ? -1 : 0}
            >
              <DropdownLink
                key={i}
                onBlur={() => {
                  i + 1 === options.length ? setOpen(false) : null;
                }}
              >
                <>{option.label[lang]}</>
              </DropdownLink>
            </Link>
          );
        })}
      </Menu>
    </Wrapper>
  );
};
