// export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
export const FB_PIXEL_ID = '174834703226630';

export const pageview = () => {
  window.fbq('track', 'PageView');
};

export const event = (name, options = {}) => {
  window.fbq('track', name, options);
};

export const revoke = () => {
  window.fbq('consent', 'revoke');
};
export const consent = () => {
  window.fbq('consent', 'grant');
};
