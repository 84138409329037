const path = require('path');
const { initReactI18next } = require('react-i18next');

module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: ['en', 'es'],
  },
  nsSeparator: ':',
  localePath: path.resolve('./public/locales'),
  use: [initReactI18next],
  serializeConfig: false,
};
