import styled from 'styled-components';
import ChevronDownIcon from '../../../public/icons/ChevronDownIcon';
import {
  DropdownBox,
  Options,
  Option,
  Label,
  Wrapper,
} from '../Common/Select/Select.style';
import { Theme } from '../../../layout/Basic';

export const Dropdown = styled.div`
  ${Wrapper} {
    padding: 0;
  }
  ${DropdownBox} {
    padding: 0;
    margin: 0 20px;
    position: relative;
    width: 50px;
    height: 26px;
    border: 1px solid white;
    border-radius: 50px;
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    background-color: transparent;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      cursor: pointer;
    }
  }

  ${Options} {
    background-color: #e0eff1;
    right: 0;
    top: 80px;
    border: none;
    border-radius: 0;
    width: 263px;
  }

  ${Option} {
    font-size: 13px;
    font-weight: ${(props) => (props.selected === props.value ? '600' : '200')};
    background-color: transparent;
    color: #047590;

    :hover {
      background-color: rgba(4, 117, 144, 0.14);
    }
  }

  ${Label} {
    color: #ffffff;
  }

  @media (max-width: 995px) {
    display: none;
  }

  :hover {
    cursor: pointer;
  }
`;

export const MobileLanguage = styled.div`
  display: none;

  hr {
    background-color: ${Theme.color.primaryLight};
    height: 1px;
    border: none;
  }

  @media (max-width: 995px) {
    display: block;
    margin: 8px;
  }
`;

export const LangOption = styled.div`
  padding: 20px 5px;
  display: flex;

  p {
    margin: 0 0 5px 0;
  }
`;

export const Radio = styled.button`
  height: 20px;
  width: 20px;
  margin: 5px 5px 5px 0;
  cursor: pointer;
  border-radius: 100%;
  background-color: ${(props) =>
    props.lang === props.id ? 'white' : 'transparent'};
  border: 2px solid white;
  outline: 2px solid ${Theme.color.primary};
  outline-offset: -3px;
  padding: 8px;
`;

export const Arrowdown = styled(ChevronDownIcon)`
  color: inherit;
  font-size: 20px;
  padding: 0;
  margin: 4px;
  fill: white;
`;
