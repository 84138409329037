import styled from 'styled-components';
import { Theme } from '../../../../layout/Basic';

export const Wrapper = styled.div`
  background-color: ${Theme.color.primaryLight};
  padding: 40px;
  position: fixed;
  z-index: 123;
  bottom: 0;
  width: 100vw;
  box-shadow: 0px -1px 9px -7px rgba(0, 0, 0, 0.75);

  @media print {
    display: none;
  }
`;

export const CookiesTop = styled.div`
  max-height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
  h2,
  h3 {
    margin: 0;
    padding: 0;
  }

  p {
    font-size: 14px;
    margin: 0;
    padding: 10px 0;
  }
  a {
    color: ${Theme.color.primary};
  }

  b {
    font-size: 14px;
    padding: 0;
  }
  padding-bottom: 20px;
`;

export const CookiesBottom = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
`;
export const CookiesTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;

  td,
  th {
    border: 1px solid ${Theme.color.hrGrey};
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: ${Theme.color.darkBeige};
  }

  tr:hover {
    background-color: #ddd;
  }
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: ${Theme.color.primary};
    color: white;
  }
`;
