import styled, { css } from 'styled-components';
import { Theme } from '../../../../layout/Basic';

const Primary = css`
  color: white;
  background-color: ${Theme.color.primary};
  > svg {
    fill: white;
    font-size: 18px;
  }
  :hover {
    color: ${Theme.color.primary};
    background-color: ${Theme.color.primaryLight};
    > svg {
      fill: ${Theme.color.primary};
    }
  }
`;
const Secondary = css`
  color: ${Theme.color.primary};
  background-color: ${Theme.color.primaryLight};
  > svg {
    fill: ${Theme.color.primary};
    font-size: 18px;
  }
  :hover {
    color: white;
    background: ${Theme.color.primary};
    > svg {
      fill: white;
    }
  }
`;

export const BtnSolid = styled.button`
  ${({ secondary }) => (secondary ? Secondary : Primary)}
  font-size: 14px;
  padding: 1em;
  height: 45px;
  font-weight: 600;
  border-radius: ${(props) => (props.square ? '3px' : '15px')};
  transition: 0.3s;
  cursor: pointer;
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};
  border: 1px solid ${Theme.color.primary};
  > svg {
    font-size: 18px;
    margin-right: 5px;
    margin-bottom: 2px;
  }
  :active {
    box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
  }
  :focus {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
    border: 1px solid #206e80;
  }
  :disabled {
    cursor: not-allowed;
    text-decoration: none;
    color: #323232;
    > svg {
      fill: #323232;
    }
  }

  @media (max-width: 850px) {
    font-size: 12px;
    padding: 8px;
    height: 34px;
    width: auto;
  }
`;
export const BtnText = styled.button`
  color: ${(props) => (props.primary ? Theme.color.primaryDark : 'black')};
  border: ${(props) =>
    props.outlined ? `2px solid ${Theme.color.primaryDark}` : 'none'};
  background: transparent;
  height: 30px;
  border-radius: 5px;
  font-size: 13px;
  transition: 0.3s;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
  min-width: 100px;
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};
  > svg {
    font-size: 16px;
    margin: 0 5px 2px 5px;
    fill: ${(props) => (props.primary ? Theme.color.primaryDark : 'black')};
  }
  :hover {
    cursor: pointer;
    border-color: ${Theme.color.primary};
    text-decoration: ${(props) => (props.outlined ? `none` : 'underline')};
    background-color: ${(props) =>
      props.outlined ? `${Theme.color.primaryLight}` : 'none'};
  }
  :focus {
    color: ${Theme.color.primary};
    > svg {
      fill: ${Theme.color.primary};
    }
  }
  :disabled {
    cursor: not-allowed;
    text-decoration: none;
    color: #323232;
    > svg {
      fill: #323232;
    }
  }
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

export const BtnIcon = styled.button`
  color: ${(props) =>
    props.primary
      ? Theme.color.primary
      : props.secondary
      ? 'white'
      : Theme.color.black1};
  background-color: transparent;
  border-radius: 15px;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  padding: 0;
  > svg {
    font-size: 24px;

    fill: ${(props) =>
      props.primary
        ? Theme.color.primary
        : props.secondary
        ? 'white'
        : Theme.color.black1};
  }

  :hover {
    color: ${(props) => (props.primary ? 'white' : `${Theme.color.primary}`)};
    > svg {
      fill: ${(props) => (props.primary ? 'white' : `${Theme.color.primary}`)};
    }
  }
  :focus {
    filter: brightness(75%);
  }
  :disabled {
    cursor: not-allowed;
    text-decoration: none;
    color: #323232;
    > svg {
      fill: #323232;
    }
  }
  :active {
    filter: brightness(55%);
  }
`;
