import styled from 'styled-components';
import ChevronDownIcon from '../../../../public/icons/ChevronDownIcon';
import { Theme } from '../../../../layout/Basic';

export const Wrapper = styled.div`
  padding-top: 15px;
`;

export const DropdownBox = styled.button`
  border: ${(props) =>
    props.open ? '3px solid rgba(4, 117, 144, 0.2)' : '1px solid #6f6f6f'};
  background-color: transparent;
  border-radius: 5px;
  width: 211px;
  height: 40px;
  padding: 0 20px;
  margin-bottom: 10px;
  position: relative;
  z-index: 2;

  :hover {
    cursor: pointer;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.03);
  }
`;

export const Label = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: ${Theme.color.grey};
  //text-transform: capitalize;
`;
export const Icon = styled(ChevronDownIcon)`
  font-size: 16px;
  fill: white;
  transform: ${(props) => (props.open ? 'rotate(-180deg)' : 'none')};
  transition: 0.3s;
  margin: 2px;
`;
export const Options = styled.div`
  display: flex;
  opacity: ${(props) => (props.open ? '1' : '0')};
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  z-index: 11;
  background-color: ${Theme.color.lightBeige};
  transition: all 0.3s linear;
  border: 1px solid ${Theme.color.grey};
  width: 211px;
  flex-direction: column;
  border-radius: 5px;
  position: absolute;
  padding: 10px 0;
`;
export const Option = styled.button`
  text-decoration: none;
  //text-transform: capitalize;
  font-size: 14px;
  color: ${(props) =>
    props.selected === props.value ? Theme.color.primary : Theme.color.grey};
  background-color: transparent;
  border: none;
  text-align: start;
  padding: 10px;

  svg {
    font-size: ${(props) =>
      props.value === 'small'
        ? '16px'
        : props.value === 'large'
        ? '30px'
        : '24px'};
    fill: ${Theme.color.black1};
    text-align: center;
  }

  :hover {
    cursor: pointer;
    background-color: ${Theme.color.primaryLight};
  }
`;
