import { Wrapper, CookiesTop, CookiesBottom } from './CookieBanner.style.js';
import { useTranslation } from 'next-i18next';
import { Button } from '../../Common/Button/Button';
import { useEffect, useState } from 'react';
import { hasCookie, setCookie } from 'cookies-next';
import { CookiesModal } from './CookiesModal';
export const CookieBanner = () => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const [showConsent, setShowConsent] = useState(true);

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setShowConsent(hasCookie('cookie_policy'));
  }, []);

  const acceptAllCookie = () => {
    setShowConsent(true);
    setCookie('cookie_policy', 'true', {});
    setCookie('google_analytics', 'true', {});
    setCookie('fb_pixel', 'true', {});
  };

  const acceptEssentialCookie = () => {
    setShowConsent(true);
    setCookie('cookie_policy', 'true', {});
    setCookie('google_analytics', 'false', {});
    setCookie('fb_pixel', 'false', {});
  };

  if (!showConsent) {
    return (
      <>
        <CookiesModal open={open} onClose={onClose} />
        <Wrapper aria-label="Cookies on the florentine codex">
          <CookiesTop>
            <h2>{t('Cookies on the florentine codex')} </h2>
            <p>
              {t('We use some essential cookies to make this service work.')}
            </p>
            <p>
              {t(
                'We’d also like to use analytics cookies so we can understand how you use the service and make improvements.',
              )}
            </p>
          </CookiesTop>

          <CookiesBottom>
            <Button
              square
              onClick={() => {
                acceptAllCookie();
              }}
            >
              {t('Accept analytics cookies')}
            </Button>
            <Button
              square
              secondary
              onClick={() => {
                acceptEssentialCookie();
              }}
            >
              {t('Reject analytics cookies')}
            </Button>
            <Button
              type={'text'}
              onClick={() => {
                setOpen(true);
              }}
            >
              {t('View cookies')}
            </Button>
          </CookiesBottom>
        </Wrapper>
      </>
    );
  }
};
