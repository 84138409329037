import {
  ACWrapper,
  NoOption,
  Options,
  DropdownInput,
  ImgSearchOptions,
  TxtSearchOptions,
} from './SearchAutoComplete.style';
import { useEffect, useState } from 'react';
import { useOuterClick } from '../../hooks/useOuterClick';
import { Spinner } from '../Common/Spinner/Spinner';
import { Theme } from '../../../layout/Basic';
import { useTranslation } from 'next-i18next';
import { Button } from '../Common/Button/Button';
import TextIcon from '../../../public/icons/TextIcon';
import ImageIcon from '../../../public/icons/ImageIcon';
export const SearchAutoComplete = ({
  options,
  onChange,
  onSelect,
  label,
  loading,
}) => {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState('');
  const { t } = useTranslation('common');
  const innerRef = useOuterClick((ev) => {
    setOpen(false);
  });
  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      setOpen(false);
      setInput('');
      e.target.value = '';
    } else if (e.key === 'Enter') {
      onSelect('text', input);
      setOpen(false);
      setInput('');
      e.target.value = '';
    }
  };

  useEffect(() => {
    if (input === '' || null) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [input]);

  return (
    <ACWrapper ref={innerRef} onKeyDown={handleKeyDown}>
      <DropdownInput
        onKeyDown={handleKeyDown}
        onChange={(e) => onChange(e.target.value) + setInput(e.target.value)}
        onBlur={(e) => (e.target.value = '')}
        open={open}
        aria-label={t('search')}
        placeholder={`${t('search')} ${label}`}
      />
      <Options open={open}>
        <TxtSearchOptions>
          <>
            <TextIcon /> {t('search texts for')}:
          </>
          <Button
            type="text"
            onClick={() => {
              onSelect('text', input);
              setOpen(false);
            }}
          >
            <b> {input} </b>
          </Button>
        </TxtSearchOptions>
        {!loading ? (
          <>
            {options && options.length > 0 ? (
              <ImgSearchOptions>
                <>
                  <ImageIcon /> {t('search images for')}:
                </>
                {options.map((option, i) => {
                  return (
                    <Button
                      type="text"
                      key={i}
                      onClick={() => {
                        onSelect('image', option);
                        setOpen(false);
                      }}
                    >
                      <b>{option.label}</b>
                      <span>{t(option.lang)}</span>
                    </Button>
                  );
                })}
              </ImgSearchOptions>
            ) : (
              <NoOption>{t('no results')}</NoOption>
            )}
          </>
        ) : (
          <Spinner color={Theme.color.primary} />
        )}
      </Options>
    </ACWrapper>
  );
};
