import {
  Wrapper,
  DropdownBox,
  Option,
  Options,
  Label,
  Icon,
} from './Select.style.js';
import { useState } from 'react';
import { useOuterClick } from '../../../hooks/useOuterClick';
import { useTranslation } from 'next-i18next';

export const Select = ({
  options,
  aria,
  initOption,
  onSelect,
  placeholder,
  icon,
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(initOption);
  const { t } = useTranslation('common');

  const innerRef = useOuterClick((ev) => {
    setOpen(false);
  });

  const handleSelect = (value) => {
    setSelected(value);
    setOpen(false);
    onSelect(value);
  };

  return (
    <Wrapper ref={innerRef}>
      <DropdownBox
        open={open}
        onClick={() => setOpen(!open)}
        aria-label={aria}
        id={`id_${aria}`}
      >
        <Label>
          {t(`${placeholder}`)} {t(`${selected}`)}
          <Icon open={open} />
        </Label>
      </DropdownBox>
      <Options open={open} role="listbox" aria-labelledby={`id_${aria}`}>
        {options.map((option, i) => {
          return (
            <Option
              role={'option'}
              aria-selected={selected}
              tabIndex={open ? 0 : -1}
              key={i}
              value={option}
              selected={selected}
              onClick={() => handleSelect(option)}
            >
              {icon} {t(`${option}`)}
            </Option>
          );
        })}
      </Options>
    </Wrapper>
  );
};
